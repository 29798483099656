body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #000;
}

header {
  display: flex;
  background-color: #fff;
  border-bottom: 2px solid #dadada;
}

.container {
  width: 100%;
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
}

header .container {
  display: flex;
  flex: 1;
  align-items: center;
}

header .container-left {
  align-items: flex-start;
  display: flex;
  flex: 1;
  align-items: center;
}

.header-logo {
  height: 32px;
}

.header-link {
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  border-radius: 5px;
}

.header-link:hover {
  background-color: #f1f1f1;
}

.projects-filter {
  display: flex;
  margin-bottom: 24px;
}

.projects-filter input {
  flex: 1;
  padding: 12px;
}

.project-item {
  background: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  margin-bottom: 8px;
}

.project-item.close .project-details {
  display: none;
}

.project-item.open .project-details {
  display: block;
}

.project-last-run {
  font-weight: lighter;
  font-size: 12px;
  display: inline-block;
  margin-right: 8px;
}

.project-title {
  display: flex;
  padding: 8px 24px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.project-title-left {
  align-items: flex-start;
  display: flex;
  flex: 1;
  align-items: center;
}

.project-title-right {
  display: flex;
  align-items: center;
}

.project-details {
  border-top: 1px solid #eaeaea;
  background-color: #fafafa;
  padding: 8px 24px;
}

.branch-unknown,
.branch-unknown a {
  font-style: italic;
  text-decoration: none;
}

.branch-created,
.branch-waiting_for_resource,
.branch-preparing,
.branch-pending,
.branch-created a,
.branch-waiting_for_resource a,
.branch-preparing a,
.branch-pending a {
  color: #e67e22;
  text-decoration: none;
}

.branch-running,
.branch-running a {
  color: #2980b9;
  text-decoration: none;
}

.branch-success,
.branch-success a {
  color: #27ae60;
  text-decoration: none;
}

.branch-failed,
.branch-failed a {
  color: #c0392b;
  text-decoration: none;
}

.branch-canceled,
.branch-skipped,
.branch-manual,
.branch-scheduled,
.branch-canceled a,
.branch-skipped a,
.branch-manual a,
.branch-scheduled a {
  color: #2c3e50;
  text-decoration: none;
}


.badge {
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 8px;
  margin: 0 12px;
  text-align: center;
  width: 100px;
}

.pipeline-dot {
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 4px;
  margin: 0 4px;
  text-align: center;
  width: 8px;
  font-size: 12px;
}

.badge-created,
.badge-waiting_for_resource,
.badge-preparing,
.badge-pending {
  background-color: #e67e22;
}

.badge-running {
  background-color: #2980b9;
}

.badge-success {
  background-color: #27ae60;
}

.badge-failed {
  background-color: #c0392b;
}

.badge-canceled,
.badge-skipped,
.badge-manual,
.badge-scheduled {
  background-color: #2c3e50;
}

@media screen and (max-width: 1000px) {
  .project-title {
    flex-direction: column;
    font-size: 14px;
    overflow: hidden;
  }

  .project-title-right {
    justify-content: flex-end;
  }

  .pipeline-dot {
    font-size: 8px;
    margin: 0 2px;
  }

  .badge {
    padding: 4px;
  }

  .project-last-run {
    display: none;
  }
}
